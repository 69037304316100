<template>
  <div class="register-custom">
    <header v-if="!isSingle" class="modal-header pt50 center-xs px60 h4 weight-700 relative">
      {{ $t('Register') }}
      <i slot="close" class="pointer icon-close icon-position" @click="close" />
    </header>

    <div class="modal-content pt30 pb30 px60">
      <p class="visible-xs m0 mb15 cl-black h6 align-center">
        {{ $t('Register by e-mail') }}
      </p>
      <form @submit.prevent="register" novalidate>
        <base-input
          class="mb10"
          type="email"
          name="email"
          autocomplete="email"
          v-model="email"
          @blur="$v.email.$touch()"
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <div class="row mb10">
          <base-input
            class="col-xs-6"
            type="text"
            name="first-name"
            autocomplete="given-name"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
            :placeholder="$t('First name *')"
            :validations="[
              {
                condition: !$v.firstName.required && $v.firstName.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.firstName.minLength,
                text: $t('Name must have at least 2 letters.')
              }
            ]"
          />
          <base-input
            class="col-xs-6"
            type="text"
            name="last-name"
            autocomplete="last-name"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
            :placeholder="$t('Last name *')"
            :validations="[
              {
                condition: !$v.lastName.required && $v.lastName.$error,
                text: $t('Field is required.')
              }
            ]"
          />
        </div>
        <base-input
          class="mb10"
          type="password"
          name="password"
          ref="password"
          autocomplete="new-password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[
            {
              condition: !$v.password.required && $v.password.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.password.minLength && $v.password.$error,
              text: $t('Password must have at least 6 letters.')
            }
          ]"
        />
        <base-input
          class="mb10"
          type="password"
          name="password-confirm"
          autocomplete="new-password"
          v-model="rPassword"
          @blur="$v.rPassword.$touch()"
          :placeholder="$t('Repeat password *')"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.rPassword.sameAsPassword && $v.rPassword.$error,
              text: $t('Passwords must be identical.')
            }
          ]"
        />
        <div class="row m0 middle-xs">
          <base-checkbox
            class="terms-checkbox mb20 cl-black"
            id="register-terms"
            v-model="conditions"
            @blur="$v.conditions.$reset()"
            @change="$v.conditions.$touch()"
            :validations="[{
              condition: !$v.conditions.required && $v.conditions.$error,
              text: $t('Field is required')
            }]"
          >
            {{ $t('I have read and understand the') }}
            <router-link :to="'/i/' + $t('privacy-policy')" target="_blank">
              {{ $t('Privacy and Cookies Policy') }}
            </router-link>
            {{ $t('and agree to receive personalized commercial communications from E-Garderobe by e-mail.') }} *
          </base-checkbox>
          <base-checkbox
            class="terms-checkbox mb20 cl-black"
            id="register-gdpr"
            v-model="conditions1"
            @blur="$v.conditions1.$reset()"
            @change="$v.conditions1.$touch()"
            :validations="[{
              condition: !$v.conditions1.required && $v.conditions1.$error,
              text: $t('Field is required')
            }]"
          >
            {{ $t('I hereby agree to have my personal data process by E-Garderobe seated in Warsaw in accordance with rec. 6 art. 1 a) for the General Data Protection Regulation (GDPR) in order to fulfill and conclude the agreement. I have the right of access the content of my data to correct, erase or restrict an access. I reserve the right to withhold, revoke or limit the agreement anytime without affecting the lawfulness of data processing which took a place before the agreement cancelation.') }} *
          </base-checkbox>
          <base-checkbox
            class="terms-checkbox mb20 cl-black"
            id="register-policy"
            v-model="conditions2"
            @blur="$v.conditions2.$reset()"
            @change="$v.conditions2.$touch()"
            :validations="[{
              condition: !$v.conditions2.required && $v.conditions2.$error,
              text: $t('Field is required')
            }]"
          >
            {{ $t('I hereby that I have been informed that after E-Garderobe accomplishes my order (understood as delivery of rented merchandise to the designated address by the customer) I will not be entitled to withdraw from the contract based on art. 27 act from 30th May 2014 on consumer rights (Journal of Laws of 2017, item 683, as amended) and I state to agree to the above.') }} *
          </base-checkbox>
          <button-full :disabled="$v.$invalid" class="mb20 w-100 " type="submit">
            {{ $t('Register an account') }}
          </button-full>
        </div>
        <div :class="{ 'hidden-xs': !isSingle }" class="center-xs">
          <span>
            {{ $t('or') }}
            <a href="#" class="loginLink cl-black" @click.prevent="switchElem">
              {{ $t('login to your account') }}
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Register from '@vue-storefront/core/compatibility/components/blocks/Auth/Register'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { GoogleRecaptcha } from 'src/modules/vsf-recaptcha'
import rootStore from '@vue-storefront/core/store'

export default {
  data () {
    return {
      conditions: false,
      conditions1: false,
      conditions2: false
    }
  },
  props: {
    isSingle: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      minLength: minLength(2),
      required
    },
    lastName: {
      required
    },
    password: {
      minLength: minLength(6),
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    conditions: {
      sameAs: sameAs(() => true)
    },
    conditions1: {
      sameAs: sameAs(() => true)
    },
    conditions2: {
      sameAs: sameAs(() => true)
    }
  },
  mixins: [Register],
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  },
  beforeCreate () {
    registerModule(GoogleRecaptcha)
  },
  mounted () {
    this.showRecaptchaBadge()
  },
  beforeDestroy () {
    this.hideRecaptchaBadge()
  },
  methods: {
    showRecaptchaBadge () {
      if (document.getElementsByClassName('grecaptcha-badge').length) document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    hideRecaptchaBadge () {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
    },
    callRegister () {
      this.$bus.$emit('notification-progress-start', i18n.t('Registering the account ...'))
      let storeId = this.$store.state.storeView.storeId
      this.$store.dispatch('user/register', { email: this.email, password: this.password, firstname: this.firstName, lastname: this.lastName, store_id: storeId, gToken: this.$store.state.googleRecaptcha.google_generated_token }).then(async (result) => {
        Logger.debug(result, 'user')()
        this.$bus.$emit('notification-progress-stop')
        if (result.code !== 200) {
          this.onFailure(result)
          // If error includes a word 'password', focus on a corresponding field
          if (result.result.includes('password')) {
            this.$refs['password'].setFocus('password')
            this.password = ''
            this.rPassword = ''
          }
        } else {
          await this.$store.dispatch('user/login', { username: this.email, password: this.password })
          this.onSuccess()
          this.close()
        }
      }).catch(err => {
        this.onFailure({ result: 'Unexpected authorization error. Check your Network conection.' })
        this.$bus.$emit('notification-progress-stop')
        Logger.error(err, 'user')()
      })
    },
    async register () {
      try {
        if (this.$v.$invalid) {
          this.$v.$touch()
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Please fix the validation errors'),
            action1: { label: this.$t('OK') }
          })
          return
        }
        await this.$store.dispatch('googleRecaptcha/isVerifiedCaptcha')
        if (this.$store.state.googleRecaptcha.google_generated_token) {
          this.callRegister()
        }
      } catch (error) {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'error',
          message: error,
          action1: { label: i18n.t('OK') }
        })
      }
    },
    onSuccess () {
      Vue.gtm.trackEvent({
        event: 'usersignupform',
        eventCategory: 'user',
        eventAction: 'signup',
        'user-email': this.email,
        'user-name': this.firstName,
        'user-surname': this.lastName
      })
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.modal-header {
  @media (max-width: 767px) {
    padding: 30px 0 0 0;
    font-size: 20px;
  }
}
.icon-position {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #646464;
   @media (max-width: 767px) {
    right: 15px;
    top: 15px;
  }
}
.terms-checkbox {
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.register-btn {
  font-weight: 400;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 400px) {
    padding: 10px 40px;
  }
}
.loginLink {
  &:hover {
    color: $main;
    text-decoration: underline;
  }
}
.modal-content {
  @media (max-width: 400px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}
</style>

<style>
/* recaptcha badge */
.grecaptcha-badge {
  z-index: 10;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: -2px !important;
  bottom: 20px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
</style>
