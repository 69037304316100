var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-custom"},[(!_vm.isSingle)?_c('header',{staticClass:"modal-header pt50 center-xs px60 h4 weight-700 relative"},[_vm._v("\n    "+_vm._s(_vm.$t('Register'))+"\n    "),_c('i',{staticClass:"pointer icon-close icon-position",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-content pt30 pb30 px60"},[_c('p',{staticClass:"visible-xs m0 mb15 cl-black h6 align-center"},[_vm._v("\n      "+_vm._s(_vm.$t('Register by e-mail'))+"\n    ")]),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('base-input',{staticClass:"mb10",attrs:{"type":"email","name":"email","autocomplete":"email","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('div',{staticClass:"row mb10"},[_c('base-input',{staticClass:"col-xs-6",attrs:{"type":"text","name":"first-name","autocomplete":"given-name","placeholder":_vm.$t('First name *'),"validations":[
            {
              condition: !_vm.$v.firstName.required && _vm.$v.firstName.$error,
              text: _vm.$t('Field is required.')
            },
            {
              condition: !_vm.$v.firstName.minLength,
              text: _vm.$t('Name must have at least 2 letters.')
            }
          ]},on:{"blur":function($event){return _vm.$v.firstName.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-6",attrs:{"type":"text","name":"last-name","autocomplete":"last-name","placeholder":_vm.$t('Last name *'),"validations":[
            {
              condition: !_vm.$v.lastName.required && _vm.$v.lastName.$error,
              text: _vm.$t('Field is required.')
            }
          ]},on:{"blur":function($event){return _vm.$v.lastName.$touch()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_vm._v(" "),_c('base-input',{ref:"password",staticClass:"mb10",attrs:{"type":"password","name":"password","autocomplete":"new-password","placeholder":_vm.$t('Password *'),"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: _vm.$t('Password must have at least 6 letters.')
          }
        ]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password-confirm","autocomplete":"new-password","placeholder":_vm.$t('Repeat password *'),"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword && _vm.$v.rPassword.$error,
            text: _vm.$t('Passwords must be identical.')
          }
        ]},on:{"blur":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}}),_vm._v(" "),_c('div',{staticClass:"row m0 middle-xs"},[_c('base-checkbox',{staticClass:"terms-checkbox mb20 cl-black",attrs:{"id":"register-terms","validations":[{
            condition: !_vm.$v.conditions.required && _vm.$v.conditions.$error,
            text: _vm.$t('Field is required')
          }]},on:{"blur":function($event){return _vm.$v.conditions.$reset()},"change":function($event){return _vm.$v.conditions.$touch()}},model:{value:(_vm.conditions),callback:function ($$v) {_vm.conditions=$$v},expression:"conditions"}},[_vm._v("\n          "+_vm._s(_vm.$t('I have read and understand the'))+"\n          "),_c('router-link',{attrs:{"to":'/i/' + _vm.$t('privacy-policy'),"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.$t('Privacy and Cookies Policy'))+"\n          ")]),_vm._v("\n          "+_vm._s(_vm.$t('and agree to receive personalized commercial communications from E-Garderobe by e-mail.'))+" *\n        ")],1),_vm._v(" "),_c('base-checkbox',{staticClass:"terms-checkbox mb20 cl-black",attrs:{"id":"register-gdpr","validations":[{
            condition: !_vm.$v.conditions1.required && _vm.$v.conditions1.$error,
            text: _vm.$t('Field is required')
          }]},on:{"blur":function($event){return _vm.$v.conditions1.$reset()},"change":function($event){return _vm.$v.conditions1.$touch()}},model:{value:(_vm.conditions1),callback:function ($$v) {_vm.conditions1=$$v},expression:"conditions1"}},[_vm._v("\n          "+_vm._s(_vm.$t('I hereby agree to have my personal data process by E-Garderobe seated in Warsaw in accordance with rec. 6 art. 1 a) for the General Data Protection Regulation (GDPR) in order to fulfill and conclude the agreement. I have the right of access the content of my data to correct, erase or restrict an access. I reserve the right to withhold, revoke or limit the agreement anytime without affecting the lawfulness of data processing which took a place before the agreement cancelation.'))+" *\n        ")]),_vm._v(" "),_c('base-checkbox',{staticClass:"terms-checkbox mb20 cl-black",attrs:{"id":"register-policy","validations":[{
            condition: !_vm.$v.conditions2.required && _vm.$v.conditions2.$error,
            text: _vm.$t('Field is required')
          }]},on:{"blur":function($event){return _vm.$v.conditions2.$reset()},"change":function($event){return _vm.$v.conditions2.$touch()}},model:{value:(_vm.conditions2),callback:function ($$v) {_vm.conditions2=$$v},expression:"conditions2"}},[_vm._v("\n          "+_vm._s(_vm.$t('I hereby that I have been informed that after E-Garderobe accomplishes my order (understood as delivery of rented merchandise to the designated address by the customer) I will not be entitled to withdraw from the contract based on art. 27 act from 30th May 2014 on consumer rights (Journal of Laws of 2017, item 683, as amended) and I state to agree to the above.'))+" *\n        ")]),_vm._v(" "),_c('button-full',{staticClass:"mb20 w-100 ",attrs:{"disabled":_vm.$v.$invalid,"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('Register an account'))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"center-xs",class:{ 'hidden-xs': !_vm.isSingle }},[_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('or'))+"\n          "),_c('a',{staticClass:"loginLink cl-black",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem($event)}}},[_vm._v("\n            "+_vm._s(_vm.$t('login to your account'))+"\n          ")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }